import axios from "axios";
import { networkGlobals } from "./networkUtils";
const serverHost = 'https://api.a-dmin.ai';
const activeFeatures = {}

export const hasFeature = async (host, brandID, featureKey) => {
    host ||= networkGlobals.host
    brandID ||= networkGlobals.brand.id
    const key = `${host}-${brandID}`
    if (!activeFeatures[key]) {
        activeFeatures[key] = await getSettingsDO(host, brandID, 'app_features')
    }
    return activeFeatures[key].find((f) => f.param_name === featureKey)?.param_value === "true"
}

/** get list of settings */
export const getSettingsDO = async (host, brandID, type) => {
    return new Promise((resolve, reject) => {
        axios.get(`${networkGlobals.serverHost}/api/settings/?host=${host}&brand=${brandID}&type=${type}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get profile:', error)
                reject(error);
            })
    })
}

/** put settings */
export const updateSettingsDO = async (host, brand, data) => {
    return new Promise((resolve, reject) => {
        axios.put(`${networkGlobals.serverHost}/api/settings/`, { data, host, brand })
       
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from get profile:', error)
                reject(error);
            })
    })
}

export const deploySettingsDO = async (host, type, brand) => {
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/settings/deploy/`,{host,type, brand})
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                console.error('error from deploy settings:', error)
                reject(error);
            })
    })
} 